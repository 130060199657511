<template>
  <calculator-card/>
</template>
<script>
import calculatorCard from "@/components/calculator/calculatorCard";
export default {
  components: {
    calculatorCard
  },
  name: "calculator"
}
</script>

<style scoped>

</style>