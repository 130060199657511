<template>
  <div class="list-services">
    <div class="position-relative" style="top: -2px; margin-bottom: 2px">Выбор услуг:</div>
    <b-list-group>
      <b-list-group-item
          class="list-service text-right"
          v-for="(serv, i) in services"
          :key="i"
      >
        <div
            class="d-flex flex-wrap align-items-center justify-content-end"
            v-if="serv.variants"
        >
          <div
              class="w-100 w-100 h-100 text-uppercase font-weight-bolder position-relative"
              style="font-size: 0.88rem"
              v-b-toggle="'accordion' + i"
          >
            <svg
                class="position-absolute clear-button"
                v-if="serv.variants && $data[serv.key.split('_')[0] + 'Pack'].label"
                @click.stop="clearPack(serv.key)"
                width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="14.1572" y="0.722656" width="3" height="19" transform="rotate(45 14.1572 0.722656)"
                    fill="#7367F0"/>
              <rect x="16.2783" y="14.1562" width="3" height="19" transform="rotate(135 16.2783 14.1562)"
                    fill="#7367F0"/>
            </svg>
            {{ serv.label }}
            <svg class="ml-1" style="margin-right: 12px" width="9" height="6" viewBox="0 0 9 6" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.07129 1.53516L4.65546 4.90071C4.61693 4.93867 4.55519 4.93911 4.51612 4.9017L1.00022 1.53516"
                  stroke="#979797" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
          </div>
          <b-collapse :id="'accordion' + i" accordion="accordion_main">
            <label
                class="cursor-pointer d-flex mr-1 mt-1 align-items-center justify-content-end"
                v-for="(variant, k) in serv.variants"
                :key="k"
                :for="serv.key + '_' +k"
            >
              <span class="mr-1">{{ variant.label }}</span>
              <b-form-radio
                  :checked="$data[serv.key.split('_')[0] + 'Pack'].label === variant.label"
                  :value="true"
                  class="text-right list-type-pack"
                  :id="serv.key + '_' +k"
                  @change="setUpdatePacks(variant, serv.key)"
                  :name="'select_current_pack_'+  i"
              >
              </b-form-radio>
            </label>
          </b-collapse>
        </div>
        <label
            class="cursor-pointer w-100 h-100 text-uppercase font-weight-bolder d-flex align-items-center justify-content-end"
            style="font-size: 0.88rem"
            v-else
            :for="'service_' + i"
        >
          {{ serv.label }}
          <b-form-checkbox
              v-model="servicesPrice[serv.key]"
              :disabled="serv.key === 'unloading' || serv.key === 'acceptance' || serv.key === 'deliveryToMP'"
              style="margin-left: 13px"
              :id="'service_' + i"
              :value="serv.price"
              @change="updatePrices"
          />
        </label>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {
  BCard,
  BImg,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BListGroupItem,
  BListGroup,
  BCollapse,
  BFormRadio,
  BFormGroup,
  VBToggle,
} from 'bootstrap-vue';

export default {
  name: "listServices",
  directives: {
    'b-toggle': VBToggle
  },
  components: {
    BCollapse,
    BCard,
    BImg,
    BFormCheckbox,
    BButton,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    BListGroupItem,
    BListGroup,
    BFormGroup,
  },
  props: {
    width: [Number, String],
    height: [Number, String],
    length: [Number, String],
    maxItemInBox: Number,
    packKPD: [Number, String],
    palletsAmount: [Number, String],
    amountBoxOut: [Number, String],
    amountBox: [Number, String],
    amount: [Number, String],
  },
  data() {
    return {
      firstPack: {},
      secondPack: {},
      deliveryToMPPack: {
        label: 'WB',
        price: [350, 2000]
      },
      firstCurrentPack: [],
      secondCurrentPack: [],
      firstPackPrice: {},
      secondPackPrice: {},
      otherServPrice: 0,
      currentMp: {},
      servicesPrice: {
        unloading: 0,
        acceptance: 0,
        deliveryToMP: 0,
      },
    }
  },
  methods: {
    clearPack(name) {
      name = name.split('_')[0]
      this[name + 'Pack'] = {};
      this[name + 'CurrentPack'] = [];
      this[name + 'PackPrice'] = {};
      this.updatePrices();
    },
    calcSizePack(name) {
      let [height, width, length] = this.getHWL;
      let resSizes = [];
      if (this.length && this.width && this.height) {
        for (let size in this[name + 'Pack'].price) {
          let [w, l] = size.split('x').sort();
          if (w - height - 0.5 >= width && l - height - 0.5 >= length) resSizes.push([w, l]);
        }
        resSizes = resSizes.sort((a, b) => a[0] * a[1] - b[0] * b[1]);
        resSizes = resSizes.splice(0, 2);
        if (resSizes.length) {
          resSizes.forEach(size => {
            size = size.join('x');
            this[name + 'CurrentPack'].push({
              size,
              price: this[name + 'Pack'].price[size],
              label: this[name + 'Pack'].label
            });
          })
        } else {
          this[name + 'CurrentPack'].push({
            label: this[name + 'Pack'].label,
            size: false
          });
        }
      }
    },

    updatePrices() {
      let services = {
        firstPack: this.firstCurrentPack,
        secondPack: this.secondCurrentPack,
        otherPrice: this.getServicesPrice()
      }

      this.$emit('selectServices', services)
    },

    setUpdatePacks(variant, name) {
      let names = {
        first_pack: 'first',
        second_pack: 'second',
      }
      if (name === 'deliveryToMP_pack') {
        this.deliveryToMPPack = variant;
      }
      else {
        this.setPacks(variant, names[name]);
      }
      this.updatePrices();
    },
    getServicesPrice() {
      return Object.values(this.servicesPrice).reduce((acc, curr) => acc + curr, 0) * this.amount + this.getPriceUnloading + this.getPriceAcceptance + this.getPriceDelivery;
    },

    setPacks(variant, name) {
      this[name + 'Pack'] = variant;
      this[name + 'CurrentPack'] = [];
      if (typeof variant.price === "object") this.calcSizePack(name);
      else this[name + 'CurrentPack'] = {size: true, price: variant.price, label: variant.label}
    },
  },
  watch: {
    amount(val) {
      if (this.width && this.height && this.length && val) this.updatePrices()
    },
    width(val) {
      this.setPacks(this.firstPack, 'first');
      this.setPacks(this.secondPack, 'second');
      this.updatePrices();
    },
    height() {
      this.setPacks(this.firstPack, 'first');
      this.setPacks(this.secondPack, 'second');
      this.updatePrices();
    },
    length() {
      this.setPacks(this.firstPack, 'first');
      this.setPacks(this.secondPack, 'second');
      this.updatePrices();
    },
  },
  computed: {
    getPriceUnloading() {
      return 250 * this.amountBoxOut + 1000 * this.palletsAmount
    },

    getPriceDelivery() {
      if (this.deliveryToMPPack.price?.[0]) return this.deliveryToMPPack.price[0] * this.amountBox + this.deliveryToMPPack.price[1] * this.palletsAmount
      return 0
    },

    getPriceAcceptance() {
      let price = 12;
      if (+this.width + +this.height + +this.length < 25) price = 3;
      else if (+this.width + +this.height + +this.length < 50) price = 5;
      price++
      console.log(`price: ${price} amountBox: ${this.amountBox} palletsAmount: ${this.palletsAmount}`);
      return +this.amount * price + 280 * this.amountBox + 900 * this.palletsAmount
    },


    getHWL() {
      return [this.height, this.width, this.length].sort((a, b) => a - b)
    },
    ...mapState('services', ['services']),
  }
}
</script>

<style scoped>

.list-service {
  width: 280px;
}

.list-type-pack {
  border: none;
}

.clear-button {
  left: -8.5px;
}

</style>