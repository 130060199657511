<template>
  <div class="dimens-input-container">
    <div class="d-flex align-items-center justify-content-between w-100">
      <b-form-group>
        <label>Длина(см):</label>
        <b-form-input
            v-model="length"
            @input="function(val){dimensUpdate('length', val)}"
            type="number"
            placeholder="0 см"
        />
      </b-form-group>
      <b-form-group class="mx-1">
        <label>Ширина(см):</label>
        <b-form-input
            v-model="width"
            @input="function(val){dimensUpdate('width', val)}"
            type="number"
            placeholder="0 см"
        />
      </b-form-group>
      <b-form-group>
        <label>Высота(см):</label>
        <b-form-input
            v-model="height"
            @input="function(val){dimensUpdate('height', val)}"
            type="number"
            placeholder="0 см"
        />
      </b-form-group>
    </div>
    <div class="d-flex align-items-center justify-content-between w-100">
      <b-form-group class="mr-1">
        <label>Количество(шт):</label>
        <b-form-input
            @input="function(val){dimensUpdate('amount', val)}"
            v-model="amount"
            type="number"
            placeholder="0 шт"
        />
      </b-form-group>
      <b-form-group>
        <label>Масса(гр):</label>
        <b-form-input
            v-model="weight"
            @input="function(val){dimensUpdate('weight', val)}"
            type="number"
            placeholder="0 гр"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import {mapActions} from "vuex";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BFormGroup,
    BLink,
    BFormInput,
    BFormSelect,
    BFormCheckbox
  },
  name: "inputDimensions",
  data() {
    return {
      outBoxWidth: 40,
      outBoxHeight: 40,
      outBoxLength: 60,
      wallWidth: 0,
      minPalletVolume: 1056000,
      maxPalletVolume: 16e5,
      length: '',
      width: '',
      height: '',
      weight: '',
      amount: 1,
    }
  },
  props: {
    l: Number,
    w: Number,
    h: Number,
    m: Number,
  },
  computed: {
    boxWidth() {
      return this.outBoxWidth - this.wallWidth * 2;
    },
    boxHeight() {
      return this.outBoxHeight - this.wallWidth * 2;
    },
    boxLength() {
      return this.outBoxLength - this.wallWidth * 2;
    },
    maxBoxVolume() {
      return this.boxHeight * this.boxWidth * this.boxLength
    },

    packKPD() {
      return Math.round(this.getMaxItemInBox * this.volumeProduct / this.maxBoxVolume * 100);
    },

    volumeProduct() {
      return this.length * this.width * this.height
    },

    getMaxItemInBox() {
      if (this.length &&
          this.width &&
          this.height) {
        return this.calcMaxItemInBox( this.boxHeight, this.boxWidth,this.boxLength, this.height, this.width, this.length);
      }
      return 0
    },

    getAmountBox() {
      if (+this.amount > 0 && this.getMaxItemInBox > 0) return Math.ceil(this.amount / this.getMaxItemInBox);
      return 0
    },

    getPalletsAmount() {
      let stock = this.getAmountBox % 16;
      let am = parseInt(this.getAmountBox / 16);
      if (stock > 10) {
        am++;
        stock = 0;
      }
      return [am, stock];
    },
  },
  methods: {
    dimensUpdate(name, value) {
      this.$emit('paramsUpdate', {name, value});
      this.$emit('boxUpdate', {
        maxItemInBox: this.getMaxItemInBox,
        packKPD: this.packKPD,
        palletsAmount: this.getPalletsAmount,
        amountBox: this.getAmountBox,
      });
    },

    getMinAmount(boxHeight, boxWidth, boxLength, height, width, length) {
      //console.log(`boxHeight: ${boxHeight}, boxWidth: ${boxWidth}, boxLength: ${boxLength}, height: ${height}, width: ${width}, length: ${length} `);

      const mainVolume = Math.floor(boxWidth / width) * Math.floor(boxHeight / height) * Math.floor(boxLength / length);
      if (mainVolume) {
        let diffHeight = boxHeight % height;
        let diffWidth = boxWidth % width;
        let diffLength = boxLength % length;
        return [mainVolume, diffHeight, diffWidth, diffLength]
      }
      return false
    },

    rotateInBox(boxH, boxW, boxL, h, w, l) {
      return [
        this.getMinAmount(boxH, boxW, boxL, w, h, l),
        this.getMinAmount(boxH, boxW, boxL, h, w, l),
        this.getMinAmount(boxH, boxW, boxL, l, h, w),
        this.getMinAmount(boxH, boxW, boxL, l, w, h),
        this.getMinAmount(boxH, boxW, boxL, w, l, h),
        this.getMinAmount(boxH, boxW, boxL, h, l, w),
      ]

    },

    calcMaxItemInBox(boxH, boxW, boxL, he, wi, le) {
      let [h, w, l] = [he, wi, le].sort();
      //console.log(`index: ${i++}`)

      let maxAmount = 0;
      let variants = this.rotateInBox(boxH, boxW, boxL, h, w, l);

      variants.forEach(variant => {
        if (variant) {
          let byLength = 0;
          let byLengthMin = 0;
          let byWidth = 0;
          let byWidthMin = 0;
          let byHeight = 0;
          let byHeightMin = 0;

          //console.log(`variant: ${variant}`)
          let [amount, diffHeight, diffWidth, diffLength] = variant;
          if (diffLength > 0) {
            byLength = this.calcMaxItemInBox(boxH, boxW, diffLength, h, w, l);
            byLengthMin = this.calcMaxItemInBox(boxH - diffHeight, boxW - diffWidth, diffLength, h, w, l);
            //console.log(`byLength: ${byLength}`)
          }
          if (diffWidth > 0) {
            byWidth = this.calcMaxItemInBox(boxH, diffWidth, boxL, h, w, l);
            byWidthMin = this.calcMaxItemInBox(boxH - diffHeight, diffWidth, boxL - diffLength, h, w, l);
            //console.log(`byWidth: ${byWidth}`)
          }
          if (diffHeight > 0) {
            byHeight = this.calcMaxItemInBox(diffHeight, boxW, boxL, h, w, l);
            byHeightMin = this.calcMaxItemInBox(diffHeight, boxW - diffWidth, boxL - diffLength, h, w, l);
            //console.log(`byHeight: ${byHeight}`)
          }
          amount += Math.max(byHeight + byWidth + byLengthMin, byLength + byWidth + byHeightMin, byLength + byWidthMin + byHeight);
          if (amount > maxAmount) maxAmount = amount;
        }
      })
      return maxAmount;
    },
  },
  mounted() {

  }

}
</script>

<style scoped>
.dimens-input-container {
  width: 280px;
}
</style>