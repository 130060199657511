<template>
  <b-card class="main-calc-container">
    <div class="mb-1 d-flex justify-content-between align-items-start">
      <div>
        <h3 style="font-size: 1.4rem" class="mr-1">
          Калькулятор услуг
        </h3>
        <span>
          Данный расчёт является приблизительным
        </span>
      </div>
      <span style="font-weight: 500; color: #5E5873; font-size: 19px">{{ getTotalSum }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-start flex-wrap">
      <div class="input-result-block mb-1 mr-1">
        <inputDimensions
            @paramsUpdate="paramsUpdate"
            @boxUpdate="boxUpdate"
            :amount="amount"

        />
        <div
            v-if="prodDensity > maxDensity"
            class="text-warning my-2"
        >
          Продукт слишком тяжелый. Допустимая плотность продукта: <br> {{ maxDensity }} г/см <span style="font-size: 10px; position: relative; top: -5px">2</span>
        </div>
        <div v-if="length > 0 && width  > 0  && height  > 0">
          <div
              v-if="firstPack1 && typeof firstPack1.size === 'string'"
          >
            <h5>Первый слой упаковки</h5>
            <div>{{ firstPack1.label }}:</div>
            <div class="d-flex align-items-center my-1">
            <span>
              <b-form-radio
                  @change="selectedSizePack = firstPack1"
                  :checked="firstPack1.size === selectedSizePack.size"
                  :value="true"
                  class="mr-1"
                  name="set_bag">
                {{ firstPack1.size }}
              </b-form-radio>
            </span>
              <span v-if="firstPack2"
              >
              <b-form-radio
                  @change="selectedSizePack = firstPack2"
                  :checked="firstPack2.size === selectedSizePack.size"
                  :value="true"
                  name="set_bag">
                {{ firstPack2.size }}
              </b-form-radio>
            </span>
            </div>
            <span
                class="font-weight-bold">
              {{ firstPack1.size }}
            </span>
            - оптимальный размер - пакета.
            <span v-if="firstPack2"
            >
            В пакете
            <span
                class="font-weight-bold"
            >
              {{ firstPack2.size }}
            </span>
              товар будет более свободно расположен.
            </span>
          </div>
          <div
              v-else-if="firstPack1 && firstPack1.size === false"
          >
            <h5>Первый слой упаковки</h5>
            <div>{{ firstPack1.label }}:</div>
            <span class="text-warning" >Нет подходящих размеров упаковочных пакетов, выберите другой вид упаковки.</span>
          </div>
          <div
              v-else-if="firstPack1 && firstPack1.label && firstPack1.size === true"
          >
            <h5>Первый слой упаковки</h5>
            {{ firstPack1.label }}
          </div>
          <div
              v-if="secondPack1 && typeof secondPack1.size === 'string'"
          >
            <h5 class="mt-2">Второй слой упаковки</h5>
            <div>{{ secondPack1.label }}:</div>
            <div class="d-flex align-items-center my-1">
            <span>
              <b-form-radio
                  @change="selectedSizePack2 = secondPack1"
                  :checked="secondPack1.size === selectedSizePack2.size"
                  :value="true"
                  class="mr-1"
                  name="set_bag1">
                {{ secondPack1.size }}
              </b-form-radio>
            </span>
              <span v-if="secondPack2"
              >
              <b-form-radio
                  @change="selectedSizePack2 = secondPack2"
                  :checked="secondPack2.size === selectedSizePack2.size"
                  :value="true"
                  name="set_bag1">
                {{ secondPack2.size }}
              </b-form-radio>
            </span>
            </div>
            <span
                class="font-weight-bold">
              {{ secondPack1.size }}
            </span>
            - оптимальный размер - пакета.
            <span v-if="secondPack2"
            >
            В пакете
            <span
                class="font-weight-bold"
            >
              {{ secondPack2.size }}
            </span>
              товар будет более свободно расположен.
            </span>
          </div>
          <div
              v-else-if="secondPack1 && secondPack1.size === false"
          >
            <h5 class="mt-2">Второй слой упаковки</h5>
            <div>{{ secondPack1.label }}:</div>
            <span class="text-warning" >Нет подходящих размеров упаковочных пакетов, выберите другой вид упаковки.</span>
          </div>
          <div
              v-else-if="secondPack1 && secondPack1.label && secondPack1.size === true"
          >
            <h5 class="mt-2">Второй слой упаковки</h5>
            {{ secondPack1.label }}
          </div>
          <div>
            <h5 class="mt-2">Упаковка в короба</h5>
            <div>Для указанного количества и размера товара необходимо:</div>
            <div class="flex-wrap d-flex">
              <div class="mr-2 mt-2">
                <span>Короб(60x40x40) - </span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M8.46143 0.793173C9.45178 0.402276 10.5482 0.402276 11.5386 0.793173L18.6271 3.59022C19.0313 3.7499 19.3788 4.03147 19.6238 4.39784C19.8689 4.76421 20.0001 5.19818 20 5.64256V15.356C20.0001 15.8004 19.8689 16.2343 19.6238 16.6007C19.3788 16.9671 19.0313 17.2486 18.6271 17.4083L11.5386 20.2068C10.5482 20.5977 9.45178 20.5977 8.46143 20.2068L1.37429 17.4083C0.969897 17.2489 0.622099 16.9674 0.376759 16.601C0.131418 16.2346 5.73464e-05 15.8005 0 15.356V5.64256C5.73464e-05 5.19801 0.131418 4.76391 0.376759 4.39753C0.622099 4.03114 0.969897 3.74966 1.37429 3.59022L8.46143 0.793173ZM11.0257 2.16091C10.3655 1.90031 9.63452 1.90031 8.97429 2.16091L6.99714 2.94226L14.9829 6.04423L17.7657 4.82162L11.0257 2.15944V2.16091ZM18.5714 6.06035L10.7143 9.51854V18.9417C10.82 18.9139 10.9229 18.8801 11.0257 18.8391L18.1143 16.042C18.249 15.9887 18.3648 15.8947 18.4464 15.7725C18.528 15.6503 18.5716 15.5056 18.5714 15.3574V6.06328V6.06035ZM9.28571 18.9402V9.51854L1.42857 6.06182V15.356C1.42842 15.5041 1.47202 15.6489 1.55363 15.7711C1.63524 15.8933 1.75102 15.9872 1.88571 16.0406L8.97429 18.8376C9.07714 18.8787 9.18 18.9124 9.28571 18.9402ZM2.23429 4.82162L10 8.2373L13.0914 6.87689L4.99571 3.73095L2.23429 4.82162Z"
                      fill="#7367F0"/>
                </svg>
                <span class="font-weight-bold"> x {{ this.amountBox }} шт</span>
              </div>
              <div v-if="this.palletsAmount[0]" class="mt-1">
                <span>Паллет(1200х800) - </span>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.77083 0.332031L1.66667 1.03536L0 12.702V18.6654H5V16.9987H15V18.6654H20V12.702L18.3333 1.03536L18.2292 0.332031H1.77083ZM3.22917 1.9987H4.94833L4.21833 11.9987H1.79667L3.22917 1.9987ZM6.615 1.9987H9.16667V11.9987H5.885L6.615 1.9987ZM10.8333 1.9987H13.3858L14.115 11.9987H10.8333V1.9987ZM15.0525 1.9987H16.7708L18.2033 11.9987H15.7808L15.0525 1.9987ZM1.66667 13.6654H18.3333V16.9987H16.6667V15.332H3.33333V16.9987H1.66667V13.6654Z"
                      fill="#7367F0"/>
                </svg>
                <span class="font-weight-bold"> x {{ this.palletsAmount[0] }} шт</span>
              </div>
            </div>
            <div class="mt-2">
              <span>В один короб вместится товаров максимум: </span> <span class="font-weight-bold">{{
                maxItemInBox
              }} </span><span> с заполнением</span> на <span class="font-weight-bold">{{ packKPD }}%</span>
            </div>
            <div v-if="this.palletsAmount[0]" class="mt-2 font-italic">
              <span>На паллетах будет расположено коробов: </span>
              <span>{{ amountBox - this.palletsAmount[1] }} шт. </span>
              <span v-if="this.palletsAmount[1]"> Короба отдельно {{ this.palletsAmount[1] }} шт</span>
            </div>
          </div>

        </div>
        <div class="button-container mt-2" v-if="amountBox > 0">
          <b-button class="text-primary" variant="outline-primary"
                    v-b-modal.calc_feedback_modal
          >
            <span class="mr-1">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5.66797H11.8889" stroke="#7367F0" stroke-linecap="round"/>
<path
    d="M1 1H11.8889V13.4444C11.8889 13.857 11.725 14.2527 11.4333 14.5444C11.1416 14.8361 10.7459 15 10.3333 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V1Z"
    stroke="#7367F0" stroke-linejoin="round"/>
<path d="M4.11133 8.77734H4.88911M8.00022 8.77734H8.77799M4.11133 11.8885H4.88911M8.00022 11.8885H8.77799"
      stroke="#7367F0" stroke-linecap="round"/>
</svg>
            </span>
            Получить точный расчёт
          </b-button>
        </div>
      </div>
      <list-services
          :width="width"
          :height="height"
          :length="length"
          :maxItemInBox="maxItemInBox"
          :packKPD="packKPD"
          :amount="amount"
          :palletsAmount="palletsAmount[0]"
          :amountBoxOut="palletsAmount[1]"
          :amountBox="amountBox"
          @selectServices="selectServices"
      />
    </div>
    <b-modal
        id="calc_feedback_modal"
        hide-header
        hide-footer
        v-model="showFeedBack"
        modal-class="modal-primary"
        centered
    >
      <h3 class="text-center">Получить окончательную стоимость</h3>
      <span class="my-2 text-center">Менеджер позвонит вам и скажет точную сумму всех услуг</span>
      <b-form>
        <b-form-group>
          <label for="tel_feedback">Телефон*:</label>
          <b-form-input
              id="tel_feedback"
              type="tel"
              placeholder="9123 456 78 91"
          />
        </b-form-group>
        <b-form-group>
          <label for="name_feedback">Имя*</label>
          <b-form-input
              id="name_feedback"
              type="text"
              placeholder="Ваше имя"
          />
        </b-form-group>
        <div class="d-flex justify-content-center ">
          <b-button class="m-auto" variant="primary"
                    @click="senMessageFeedBack"
          >
            Отправить
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import inputDimensions from "../UI/inputDimensions"
import listServices from "./listServices"
import {mapState} from "vuex"
import {
  BCard,
  BImg,
  BButton,
  BDropdown,
  BModal,
  BDropdownItem,
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BListGroupItem,
  BListGroup,
  BCollapse,
  VBToggle,
  VBModal,
} from 'bootstrap-vue';
import amountBySizes from "@/components/delivery/AmountBySizes";

export default {
  name: "calculatorCard",
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle
  },
  components: {
    listServices,
    BCard,
    BImg,
    BButton,
    BDropdown,
    BModal,
    BDropdownItem,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BListGroupItem,
    BListGroup,
    BCollapse,
    inputDimensions,
  },
  data() {
    return {
      maxDensity: 0.24,
      maxDensityLimit: 0.36,
      selectedServices: {
        first_pack: '',
        second_pack: '',
      },
      pricesOtherServices: [],
      showFeedBack: false,
      checkedTest: false,
      packingType: '',
      valueTest: false,
      inputTest: false,
      bagWidth: null,
      bagHeight: null,
      sizePricePack: null,
      length: '',
      width: '',
      height: '',
      weight: '',
      amount: 1,
      sizeByPrice: null,
      selectedSizePack: {price: 0},
      selectedSizePack2: {price: 0},
      pricePack: 0,
      sumServ: 0,
      maxItemInBox: 0,
      packKPD: 0,
      palletsAmount: 0,
      amountBox: 0,
      firstPack1: {},
      firstPack2: {},
      secondPack1: {},
      secondPack2: {},
    }
  },
  methods: {
    senMessageFeedBack() {
      this.showFeedBack = false;
    },
    selectServices(services) {
      if (Array.isArray(services.firstPack)) {
        this.firstPack1 = services.firstPack[0];
        this.firstPack2 = services.firstPack[1];
      } else {
        this.firstPack1 = services.firstPack;
        this.firstPack2 = null;
      }
      if (this.firstPack1) this.selectedSizePack = this.firstPack1
      else this.selectedSizePack = {price: 0}
      if (Array.isArray(services.secondPack)) {
        this.secondPack1 = services.secondPack[0];
        this.secondPack2 = services.secondPack[1];
      } else {
        this.secondPack1 = services.secondPack;
        this.secondPack2 = null;
      }
      if (this.secondPack1) this.selectedSizePack2 = this.secondPack1
      else this.selectedSizePack2 = {price: 0}
      this.sumServ = services.otherPrice || 0;
    },
    paramsUpdate({name, value}) {
      this[name] = value;
    },
    boxUpdate({maxItemInBox, packKPD, palletsAmount, amountBox}) {
      this.maxItemInBox = maxItemInBox;
      this.packKPD = packKPD;
      this.palletsAmount = palletsAmount;
      this.amountBox = amountBox;
    },
    setSelectedServices(val, serName, sizeByPrice) {
      this.selectedServices[serName] = val;
      if (sizeByPrice) {
        if (typeof sizeByPrice === "number") {
          this.pricePack = sizeByPrice;

          this.packingType = 'membrane'
          this.sizeByPrice = [];
        } else {
          this.sizeByPrice = sizeByPrice;
          this.packingType = 'bag'
        }
      }
    },
  },
  computed: {
    ...mapState('services', ['services']),
    getTotalSum() {
      if (this.width && this.height && this.length) {
        let options = {style: 'currency', currency: 'RUB'};
        let numberFormat = new Intl.NumberFormat('ru-RU', options);
        let firstPack;
        let secondPack;
        if (this.selectedSizePack.size === true && this.selectedSizePack.price) firstPack = (+this.width + +this.height + +this.length) * this.selectedSizePack.price;
        else firstPack = +this.selectedSizePack.price || 0
        if (this.selectedSizePack2.size === true && this.selectedSizePack2.price) secondPack = (+this.width + +this.height + +this.length) * this.selectedSizePack2.price;
        else secondPack = +this.selectedSizePack2.price || 0;

        return numberFormat.format(firstPack * this.amount + secondPack * this.amount + +this.sumServ)
      }
    },
    prodVolume() {
      return this.width * this.height * this.length
    },

    prodDensity() {
      if (this.weight && this.prodVolume) {
        return this.weight / this.prodVolume
      }
      return 0
    },

    getHWL() {
      return [this.height, this.width, this.length].sort((a, b) => a - b)
    }
  },
}
</script>

<style scoped>

.input-result-block {
  max-width: 290px;
}

.main-calc-container {
  max-width: 750px;
}

</style>